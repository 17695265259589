<template>
  <div>
    <a-modal :visible="visible" title="批量自产" :width="1200" :confirmLoading="loading" @cancel="cancel" @ok="confirm">
      <a-table rowKey="id" size="small" :columns="columns" :dataSource="dataItems" :pagination="false" :scroll="{ x: 1800 }">
        <template slot="total_quantity" slot-scope="value, item">
          <a-input-number v-model="item.total_quantity" size="small" :min="0" />
        </template>

        <template slot="planned_start_time" slot-scope="value, item">
          <a-date-picker
            v-model="item.planned_start_time"
            placeholder="请选择时间"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            show-time
            size="small"
            style="width: 100%"
          />
        </template>
        <template slot="planned_end_time" slot-scope="value, item">
          <a-date-picker
            v-model="item.planned_end_time"
            placeholder="请选择时间"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            show-time
            size="small"
            style="width: 100%"
          />
        </template>
        <template slot="batch_number" slot-scope="value, item">
          <a-input v-model="item.batch_number" size="small" />
        </template>
        <template slot="remark" slot-scope="value, item">
          <a-input v-model="item.remark" size="small" />
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { productionOrderBatchInternal } from "@/api/production";

export default {
  props: ["visible", "productionOrderItem", "materialItems"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      loading: false,
      dataItems: {},
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          fixed: "left",
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "零件编号",
          dataIndex: "goods_number",
          fixed: "left",
          customRender: (_, item) => item.goods_item.number,
        },
        {
          title: "零件名称",
          dataIndex: "goods_name",
          fixed: "left",
          customRender: (_, item) => item.goods_item.name,
        },
        {
          title: "规格",
          dataIndex: "spec",
          customRender: (_, item) => item.goods_item.spec,
        },
        {
          title: "尺寸",
          dataIndex: "drawing_number",
          customRender: (_, item) => item.goods_item.drawing_number,
        },
        {
          title: "需求数量",
          dataIndex: "quantity",
          customRender: (value) => value * this.productionOrderItem.total_quantity,
        },
        {
          title: "库存数量",
          dataIndex: "inventory_quantity",
        },
        {
          title: "分类",
          dataIndex: "category_name",
          customRender: (_, item) => item.goods_item.category_name,
        },
        {
          title: "自产数量",
          dataIndex: "total_quantity",
          scopedSlots: { customRender: "total_quantity" },
        },
        {
          title: "计划开始时间",
          dataIndex: "planned_start_time",
          width: 200,
          scopedSlots: { customRender: "planned_start_time" },
        },
        {
          title: "计划完成时间",
          dataIndex: "planned_end_time",
          width: 200,
          scopedSlots: { customRender: "planned_end_time" },
        },
        {
          title: "批号",
          dataIndex: "batch_number",
          scopedSlots: { customRender: "batch_number" },
        },
        {
          title: "备注",
          dataIndex: "remark",
          scopedSlots: { customRender: "remark" },
        },
      ],
    };
  },
  methods: {
    confirm() {
      const dataItems = this.dataItems.map((item) => ({
        goods: item.goods,
        total_quantity: item.total_quantity,
        planned_start_time: item.planned_start_time,
        planned_end_time: item.planned_end_time,
        batch_number: item.batch_number,
        remark: item.remark,
      }));

      productionOrderBatchInternal(this.productionOrderItem.id, dataItems)
        .then((data) => {
          this.$message.success("自产工单创建成功");
          this.cancel();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.dataItems = this.materialItems.map((item) => ({
          ...item,
          total_quantity: item.quantity * this.productionOrderItem.total_quantity,
          planned_start_time: undefined,
          planned_end_time: undefined,
          batch_number: undefined,
          remark: undefined,
        }));
      }
    },
  },
};
</script>

<style scoped></style>
